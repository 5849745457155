import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import {CallMapMethod} from '../function_lib/common_lib';
let rf=0;
let logtxt="nn";
let queries;
let resCode="{respcode:001 invalid request}";
class Sm_Log extends React.Component{
    constructor(props){
        super(props);
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
            if(queries.rf!=null){
              rf=queries.rf;
            }
            if(queries.logtxt!=null){
                logtxt=queries.logtxt;
            }
            if(logtxt!==""){
                resCode="{\"respcode\":\"002 received successfully\"}";
            }
          }
        }
        componentDidMount(){
            var mapSim=[];
            mapSim.push({name:"curl_type",value:"submitSimul"});
            mapSim.push({name:"res_func",value:"callBackSimResp"});
            mapSim.push({name:"ref_id",value:rf});
            mapSim.push({name:"ref_resp",value:logtxt});
            mapSim["res_func"]="callBackSimResp";
            mapSim["curl_type"]="submitSimul";
            CallMapMethod(this,mapSim);
        }
        callBackSimResp(data,msg){
           // alert(data);
            $("#mainResp").html(msg);
        }
        render(){
            return(<div>
                {logtxt}<br>
                </br>
                {resCode}<br></br>
                <div id="mainResp"></div>
            </div>)
        }
}
export default Sm_Log;