import $ from 'jquery';
import React from 'react';
import Header from './header';

import imgYear from '../assets/images/year.png';
import defaultPic from '../assets/images/default.jpg'

class Dashboard extends React.Component{
    render(){
        const adminOption=()=>{
            return(<div>
                
            </div>)
        }
        return(<div>
           <Header/>
           
           <div className="container-fluid mt-3">
	<div className="row">
	
                <div className="col-md-3">
                   
                    <div className="card card-widget widget-user-2">
                        <div className="widget-user-header bg-warning">
                            <div className="widget-user-image">
                                <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                            </div>
                            <h3 className="widget-user-username"></h3>
                            <h5 className="widget-user-desc">Business Crew</h5>
                        </div>
                        <div className="card-footer p-0">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        Total Quiz<span className="float-right badge bg-primary">40</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        Total Assigned <span className="float-right badge bg-info">5</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        Total User Enrolled <span className="float-right badge bg-success">12</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        Pending Quiz <span className="float-right badge bg-danger">842</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-header border-transparent">
                            <h3 className="card-title">Welcome [Shipping Company]</h3>

                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="remove">
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                       
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-4 col-6">
                                    
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>150</h3>

                                            <p>Average Working</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-chart-line"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">
                                            Current Month <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-6">
                                   
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>53<sup style={{fontSize:"20px"}}>%</sup></h3>

                                            <p>Total Performance</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fal fa-clipboard-user"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">
                                            More info <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                              
                                <div className="col-lg-4 col-6">
                                 
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>30/44</h3>

                                            <p>Attempted Quiz/Total</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-user-plus"></i>
                                        </div>
                                        <a href="#" className="small-box-footer">
                                            More info <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer clearfix">

                        </div>
                    </div>
                </div>
           	
	<div className="col-lg-10 col-sm-10 col-12 col-md-10 bg-white shadow-sm">
		<div className="row bg-white shadow mt-3 ">
			
			
			<div className="col-lg-5 col-md-5 col-sm-5 col-12 mt-2 mb-2">
			  <label for="exampleFormControlSelect1">-- Select Video -- &nbsp;&nbsp;</label>
    <select className="form-control mr-2 text-black-50" id="exampleFormControlSelect1">
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
    </select>
			
			
			</div>
			
			
				<div className="col-lg-5 col-md-5 col-sm-5 col-12 mt-2 mb-2">
			  <label for="exampleFormControlSelect1">-- Select user -- &nbsp;&nbsp;</label>
    <select className="form-control mr-2 text-black-50" id="exampleFormControlSelect1">
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
      <option>Lorem Ipsum is simply dummy text of the printing and typesettin</option>
    </select>
			
			
			</div>
			
			<div className="col-lg-2 col-sm-12 col-md-2 mt-2 mb-2">
			
			<label for="exampleFormControlSelect1">Submit (to see score)&nbsp;&nbsp;</label>
			 <button type="submit" className="btn btn-primary">Submit</button>
			
			</div>
		</div>	
	 <div className="clearfix w-100"></div>	
		
		
		
 <div className="row p-2">

		
		
		
      
        <div className="col-lg-4 col-md-3 shadow-sm mb-3 bg-warning">
          <h5 className="text-black text-center pt-4 ss-font-style-2">Total Crew</h5>
			<div className="w-50 clearfix m-auto text-center">---___---___---___---</div>
          <h2 className="ss-font-style-1 text-center ss-text- pb-3 mt-2 ss-text-black-half">10</h2>
        </div>
      
		
		  
        <div className="col-lg-4 col-md-3 shadow-sm  mb-3 bg-secondary">
          <h5 className="text-black text-center pt-4 ss-font-style-2">Passed Candidate</h5>
			<div className="w-50 clearfix m-auto text-center">---___---___---___---</div>
          <h2 className="ss-font-style-1 text-center pb-3 ss-text-black-half mt-2">566</h2>
        </div>
     
		
		 
        <div className="col-lg-4 col-md-3 shadow-sm mb-3 bg-dark">
          <h5 className="text-black text-center pt-4 ss-font-style-2">Failed Candidate</h5>
			<div className="w-50 clearfix m-auto text-center">---___---___---___---</div>
          <h2 className="ss-font-style-1 text-center pb-3 mt-2 ss-text-black-half">756</h2>
        </div>
     
		
		 
        <div className="col-lg-4 col-md-3 shadow-sm mb-3 ss-bg-orange">
          <h5 className="text-black text-center pt-4 ss-font-style-2">Did Not Appear</h5>
			<div className="w-50 clearfix m-auto text-center">---___---___---___---</div>
          <h2 className="ss-font-style-1 text-center pb-3 mt-2 ss-text-black-half">5566</h2>
       </div>
	 
	 
	 
	 <div className="col-lg-4 col-md-3 shadow-sm mb-3 ss-bg1">
          <h5 className="text-black text-center pt-4 ss-font-style-2">Aggregate Marks of Candidates</h5>
		 <div className="w-50 clearfix m-auto text-center">---___---___---___---</div>
          <h2 className="ss-font-style-1 text-center pb-3 mt-2 ss-text-black-half">344</h2>
       </div>
	 
	  <div className="col-lg-4 col-md-3 shadow-sm mb-3 ss-bg2">
          <h5 className="text-black text-center pt-4 ss-font-style-2">Passing % for Quiz</h5>
		  <div className="w-50 clearfix m-auto text-center">---___---___---___---</div>
          <h2 className="ss-font-style-1 text-center pb-3 mt-2 ss-text-black-half">16</h2>
       </div>
	 
	 
		</div>
      </div>
	
    </div>
  </div>
  <div className="container-fluid shadow mb-2 mt-4">
	<div className="row ss-bg-white">
		<p className="pb-1 pt-2 pl-4">&copy; right reserved to bhandarkar Publications</p>
	</div>
</div>
        </div>)
    }
}
export default Dashboard