import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import {callIframMeth} from '../function_lib/common_lib';
import UserToken from '../function_lib/userToken';
import Header from './header';
import Footer from './footer';
//import UserManual from '../assets/Operational_Instructions.pdf';
let loginUser="";
let userObject="";
let clsObject;

let queries;
let rf;
class Start_Simul extends React.Component{
    constructor(props){
        super(props);
        if(props !=null){
          queries = QueryString.parse(this.props.location.search);
          if(queries.rf!=null){
            rf=queries.rf;
            //videoId=queries.vId;
          }
        }
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
        //$("#divEmployee").show();
        //callIframMeth("simulIF");
      }
    render(){
        return(<div className="wrapper">
            <Header/>
            <div class="content-wrapper">

            <iframe allowFullScreen={true} onLoad={()=>callIframMeth("simulIF")} id="simulIF" style={{height:"100vh",width:"100%"}} src={"https://thegamingproject.co/simulation?hostID="+rf} ></iframe>
            
            </div>
            <Footer/>
            
        </div>)
    }
}
export default Start_Simul;