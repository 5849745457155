import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {AjaxCallMethod,RespBkMthdMapp,CallMapMethod,TableDatePicker} from '../function_lib/common_lib';
import QueryString from 'query-string';

import Header from './header';
import Footer from './footer';

let rf="0";
let queries;
let loginUser="";
let userObject="";
let compToken="";
let empToken="";
let initTbResponsive=false;
class Rpt_Perfo_Reports extends React.Component{
    constructor(props){
        super(props);
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
            if(queries.rf!=null){
              rf=queries.rf;
            }
            if(queries.token!=null){
                compToken=queries.token;
            }
            if(queries.etoken!=null){
                empToken=queries.etoken;
            }
          }
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
    componentDidMount(){
        if(rf==="0"){
            this.funTabStatus(0);
            $("#navlink0").addClass("active");
        }else if(rf==="1"){
            this.funTabStatus("Pending");
            $("#navlink1").addClass("active");
        }else if(rf==="2"){
            this.funTabStatus("Completed");
            $("#navlink2").addClass("active");
        }
        RespBkMthdMapp(this,"resetURI","videoStatus");
    }
    resetURI(refAtt){
        window.location="/performanceReport?rf="+refAtt;
    }
    funTabStatus(refAtt){
        if(userObject[0].type=="3"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getQuizAssignedList"});
            mapCat.push({name:"res_func",value:"callBackQuizList"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat.push({name:"status",value:refAtt});
            mapCat["res_func"]="callBackQuizList";
            mapCat["curl_type"]="getQuizAssignedList";
            CallMapMethod(this,mapCat);
          }else if(userObject[0].type=="1"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getQuizAssignedList"});
            mapCat.push({name:"res_func",value:"callBackQuizList"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            mapCat.push({name:"status",value:refAtt});
            if(empToken!==""){
                mapCat.push({name:"emp_id",value:empToken});  
            }
            mapCat["res_func"]="callBackQuizList";
            mapCat["curl_type"]="getQuizAssignedList";
            CallMapMethod(this,mapCat);
          }else if(userObject[0].type=="2"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getQuizAssignedList"});
            mapCat.push({name:"res_func",value:"callBackQuizList"});
            
            if(compToken!==""){
                mapCat.push({name:"gb_token",value:compToken});
            }else{
                mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            }
            if(empToken!==""){
                mapCat.push({name:"emp_id",value:empToken});  
            }
            
            mapCat.push({name:"status",value:refAtt});
            //mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackQuizList";
            mapCat["curl_type"]="getQuizAssignedList";
            CallMapMethod(this,mapCat);
          }
    }
    callBackQuizList(data,msg){
        //alert(data);
        var totalQuiz=0;
        var totalPending=0;
        var totalCompleted=0;
      if(data!=="0" && data!==""){
          var assignList=JSON.parse(data);
          var strList="";
          var quizLink="";
          var quizText="";
          //alert(data);
          if(compToken!==""){
            if(assignList.length!=0){
                $("#titleRpt").append("["+assignList[0]["company_name"]+"]");
            }
          }
         
          for(var i=0;i<assignList.length;i++){
              totalQuiz++;
           quizLink="";
           if(assignList[i]["status"]=="Pending"){
               totalPending++;
               quizText="Start Animation Video";
               quizLink="/startQuiz?rf="+assignList[i]["rf_id"]+
                        "&vId="+assignList[i]["video_url"];
           }else{
               totalCompleted++;
               quizText="View Preformance";
              quizLink="/quizReport?rf="+assignList[i]["rf_id"]
           }
           strList+="<tr>"+"<td>"+(i+1)+"</td>"+
           "<td>"+assignList[i]["company_name"]+"</td>"+
           "<td>"+assignList[i]["emp_code"]+"</td>"+
           "<td>"+assignList[i]["emp_name"]+"</td>"+
           "<td>"+assignList[i]["designation"]+"</td>"+
           "<td>"+assignList[i]["category_name"]+"</td>"+
           "<td>"+assignList[i]["t_id"]+"</td>"+
           "<td>"+assignList[i]["t_quiz"]+"</td>"+
           "<td>"+assignList[i]["t_video"]+"</td>"+
           "<td>"+assignList[i]["col2"]+"</td>"+
           "<td>"+assignList[i]["n_attemp_date"]+"</td>"+
           "<td>"+assignList[i]["n_entry_date"]+"</td>"+
           "<td>"+assignList[i]["status"]+"</td>"+
           "<td><a href=\""+quizLink+"\">"+quizText+"</a></td>"+
           "</tr>";
          }
          $("#tbData").html(strList);
          if(!initTbResponsive){
            $(".dtgrid").dataTable({
                "responsive": true,
                "autoWidth": false,
            });
            initTbResponsive=true;
        }
         /*
          $(".t_totalQuiz").html(totalQuiz);
          $(".t_pendingQuiz").html(totalPending);
          $(".t_completeQuiz").html(totalCompleted);
          */
      }
    }
    render(){
        return(<div className="wrapper">
            <Header/>
                    <div className="content-wrapper">
                    <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header d-flex p-0">
                                    <h3 className="card-title p-3"></h3>
                                    <ul className="nav nav-pills ml-auto p-2">
                                        <li param1="0" className="nav-item videoStatus"><a id="navlink0" className="nav-link ordStatus" href="#tab_1" ref_node="Pending" data-toggle="tab">All</a></li>
                                        <li param1="1" className="nav-item videoStatus"><a id="navlink1" className="nav-link ordStatus" href="#tab_2" ref_node="Approved" data-toggle="tab">Pending</a></li>
                                        <li param1="2" className="nav-item videoStatus"><a id="navlink2" className="nav-link ordStatus" href="#tab_2" ref_node="Rejected" data-toggle="tab">Completed</a></li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <input type="hidden" name="viewTMapp_manual" id="viewTMapp_manual" value="#" />
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab_1">
                                            <section className="content">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        
                                                        <div className="col-md-12">
                                                            <div className="card card-primary card-outline">
                                                                <div className="card-header">
                                                                    <h3 className="card-title">
                                                                        <i className="fa fa-arrow-circle-right"></i>
                                                                        <span id="titleRpt">Animation Video Performance Report</span>
                                                                    </h3>
                                                                    <div className="card-tools">
                                                                        <div className="input-group input-group-sm" style={{width: "150px"}}>
                                                                            <a href="javascript:void(0);" id="searchEvent" name="searchEvent" className="btn btn-block btn-default btn-sm">Search</a>
                                                                        </div>
                                                                    </div>
                
                                                                </div>
                                                                <div className="card-body">
                                                                    <table className="dtgrid table table-bordered table-striped table-ms">
                                                                        <thead>
                                                                            <tr style={{backgroundColor:"#FC766AFF"}}>
                                                                                <th></th>
                                                                                <th>Company Name</th>
                                                                                <th>Emp Code</th>
                                                                                <th>Name</th>
                                                                                <th>Designation</th>
                                                                                <th>Quiz Attempted</th>
                                                                                <th>Level</th>
                                                                                <th>Quiz TimeSpend</th>
                                                                                <th>VideoTimeSpend</th>
                                                                                <th>Score Obtained</th>
                                                                                <th>Assign Date</th>
                                                                                <th>Attempted Date</th>
                                                                                <th>Status</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="tbData">
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="row" id="tbData1">
                
                
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer/>
                </div>);
    }
}
export default Rpt_Perfo_Reports