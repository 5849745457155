import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import QueryString from 'query-string';
import Header from './header';
import Footer from './footer';
import {AjaxCallMethod,CallMapMethod,TableDatePicker} from '../function_lib/common_lib';

import defaultPic from '../assets/images/9.png';
import './promo_certificate.css';


let rf=0;
let queries;
let loginUser="";
let userObject="";
class Promo_Certificate extends React.Component{

    constructor(props){
        super(props);
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
            if(queries.rf!=null){
              rf=queries.rf;
            }
          }
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getSimulatorAssignList"});
        mapCat.push({name:"res_func",value:"callBackQuizList"});
        mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        //mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat.push({name:"rf_id",value:rf});
        mapCat["res_func"]="callBackQuizList";
        mapCat["curl_type"]="getSimulatorAssignList";
        CallMapMethod(this,mapCat);
      }
      callBackQuizList(data,msg){
         // alert(data);
        if(data!=="" && data!=="0"){
            var lsUser=JSON.parse(data);
            if(lsUser.length!=0){
                $("#cName").html(lsUser[0]["emp_name"]);
                $("#cdcCode").html(lsUser[0]["emp_code"]);
                $("#compName").html(lsUser[0]["company_name"]);
                $("#simDate").html(lsUser[0]["n_entry_date"]+".");
                $("#simScore").html(lsUser[0]["col3"]);
                $("#xte_error").html("("+lsUser[0]["col2"]+").");
            }
        }
      }

    PrintDiv() 
    {  
        var style="<style>.parent{"+
            "width:800px;"+ 
            "height:750px;"+ 
            "padding:20px;"+ 
            "border: 3px solid #c72121;"+
            "border-radius: 3%;"+
        "}"+
        ".sub-parent{"+
            "width:750px; height:750; padding:20px;"+
           
        "}"+
        "@media print{"+
            "*{-webkit-print-color-adjust:exact;} "+
           "*{-color-adjust: exact;}"+
       "}"+
        "</style>";
        var divContents = document.getElementById("certificateExp").innerHTML;  
        var printWindow = window.open('', '', 'height=800,width=800');  
        printWindow.document.write('<html><head>'+style+'<title>Certificate</title>');  
        printWindow.document.write('</head><body>');  
        printWindow.document.write(divContents);  
        printWindow.document.write('</body></html>');  
        printWindow.document.close();  
        printWindow.print();
        // printWindow.close();  
     }  
    render(){
        return(
            <div className="wrapper">
                <Header/>
                <div className="content-wrapper">
                <div className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Dashboard</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
                    <div class="card-body">
                <section className="content">
                <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <input type="button" onClick={()=>this.PrintDiv()} value="Export/Print" />
                    </div>
                    <div id="certificateExp" className="col-md-9">
        <div className="parent">
        <div className="sub-parent">
      
               <span style={{fontSize:"50px",fontWeight:"bold",textDecoration:"underline"}}><center>STEERING CERTIFICATE</center></span>
               <br></br><br></br><br></br>
               <span style={{fontSize:"25px"}}>Name : <b id="cName">...</b></span><br/><br/>
               <span style={{fontSize:"25px"}}>CDC No : <b id="cdcCode">...</b></span><br/><br/>
               <br></br>
               <span style={{fontSize:"20px"}}>has successfully completed steering test conducted by <b id="compName"></b> <br></br>
               on <b id="simDate"></b> </span>
               <br></br>
               <span style={{fontSize:"20px"}}>with score of <b id="simScore">157</b> and XTE(Error) <b id="xte_error"></b></span> <br/><br/>
               <span style={{fontSize:"20px"}}>Candidate has demonstrated Knowledge, understanding and proficiency in the use of magnetic, gyro compasses, changeover from automatic pilot to hand steering and vice versa.
The seafarer can understand and acknowledge helm orders given in the English language in a clear and concise manner. 
</span>
<br></br>
<span>
<center><img src={"bits_logo.png"} height="400px" width="300px" style={{position:"absolute",left:"280px",top:"250px",align:"center"}}/></center>
</span>
             <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
             <center><img src={"bits_title.png"} height="50px" width="100px" style={{align:"center"}}/></center><br></br>
              <span style={{fontSize:"30px"}}>Course Coordinator </span><span style={{fontSize:"30px",float:"right"}}>Training Incharge</span>
        </div>
        </div>
        </div>
        </div>
        </div>
        </section>
        </div>
        </div>
        </div>
        <Footer/>
        </div>
        );
    }
}
export default Promo_Certificate;