import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import UserToken from '../function_lib/userToken';
import {GetImgCertUser,RespBkMthdMapp,displayQuest,getQuestions,AjaxCallMethod,CallMapMethod,TableDatePicker} from '../function_lib/common_lib';
import Vimeo from '@u-wave/react-vimeo'

import Header from './header';
import Footer from './footer';
import  './start_quiz.css';

let videoId;

let queries;
let rf;
function onVideoEnd(){
    //alert("test i am on end");
    $(".vidNext").css("display","block");
  }
  function onStartVim(){
    //alert("i am on start");
     $(".fullscreen").click();
  }
class Pre_Document_Simulator extends React.Component{
    startQust(){
       window.location.href="/StartSimu?rf="+rf;
    }
    constructor(props){
        super(props);
        if(props !=null){
          queries = QueryString.parse(this.props.location.search);
          if(queries.rf!=null){
            rf=queries.rf;
            //videoId=queries.vId;
          }
        }
        videoId="585422236";
    }
render(){
    return(<div className="wrapper">
<Header/>
<div class="content-wrapper">
<div style={{width:"100%",textAlign:"center"}}>
  <button type="button" onClick={()=>this.startQust()}  className="_btnNext vidNext" >Click here to Start Simulator</button>
</div>
<Vimeo
  video={videoId}
  autoplay
  allowfullscreen={"allowfullscreen"}
  mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"
  style={{height:"100vh",width:"100%"}}
  onEnd={() => onVideoEnd()}
  onPlay={()=>onStartVim()}
/>
</div>
<Footer/>
<div className="modal fade bd-example-modal-xl" role="dialog"  id="divEmployee" tabindex="-1"  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
            <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">User Manual</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <embed width="100%" height="400px" src={'Operational_Instructions.pdf#toolbar=0&navpanes=0'} toolbar="0"></embed>
      </div>
      <div className="modal-footer">
      <button type="button" className="btn _btnSetColor btn-primary" data-dismiss="modal">Get Quick Tutorial of Simulator Before Start</button>
      </div>
              </div>
            </div>
            </div>
    </div>)
}
}
export default Pre_Document_Simulator;