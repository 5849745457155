import $ from 'jquery';
import React from 'react';
import LogoImg from '../assets/images/inner_logo.png';
import UserToken from '../function_lib/userToken';
import {getAccessRolesNew,CallMapMethod} from '../function_lib/common_lib';

let listRoles;
let loginUser;
let userObject;
let companyName="";
class Header extends React.Component{
  logout(){
    localStorage.clear();
    window.location.href="./";
  }
  constructor(props){
    super(props);
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      listRoles= UserToken.getAccessRoles();
      userObject= UserToken.getUserObject();
      //alert(userObject.length);
      loginUser=userObject[0].person_name;
      companyName=userObject[0].company_name;
      //alert(listRoles.length);
    }
  }
  callBackNg(data,msg){
   var check=JSON.parse(data);
   if(check.length==0){
     alert("Invalid User !Kindly contact to Admin")
    localStorage.clear();
    window.location.href="./";
   }
  }
  componentDidMount(){
    var map=[];
        map.push({name:"curl_type",value:"getUserStatus"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"gb_token",value:userObject[0].app_user_id});
        map.push({name:"rf",value:userObject[0].id});
        map["res_func"]="callBackNg";
        map["curl_type"]="getUserStatus";
        CallMapMethod(this,map);
    //alert(listRoles.length);
    if(listRoles.length!=0){
      var listHtm=getAccessRolesNew(listRoles);
      //alert(listHtm);
    
      $("#menu_opt").html(listHtm);
     
      // $(".nav-item").click();
    }
    
  }
    render(){
        return(<nav className="border-box main-header navbar navbar-expand-md navbar-advaiet navbar-light">
        <div className="container">
          <a href="/Dashboard" className="navbar-brand">
            <img src={LogoImg} alt="BhandarkarPub" className="brand-image elevation-3"
                 style={{opacity:"0.8"}} />
            <span className="brand-text font-weight-light"></span>
          </a>
          
          <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
    
          <div className="collapse navbar-collapse order-3" id="navbarCollapse">
           <h6>Welcome : {companyName}</h6>
            
            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            <ul id="menu_opt" className="navbar-nav">
            <li className="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Masters</a>
                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                  <li><a href="/quizcategory" className="dropdown-item">Quiz Category</a></li>
                  <li><a href="/questmaster" className="dropdown-item">Question Master</a></li>
                  <li><a href="#" className="dropdown-item">Access Roles</a></li>
                  <li><a href="#" className="dropdown-item">Subscription Plan</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Shipping A/c</a>
                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                  <li><a href="/createcompany" className="dropdown-item">Create/View Shipping Accounts</a></li>
                  <li><a href="#" className="dropdown-item">Accessibility</a></li>
                 </ul>
              </li>
              <li className="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">User A/c</a>
                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                  <li><a href="/useraccounts" className="dropdown-item">Create/View User Accounts</a></li>
                  <li><a href="#" className="dropdown-item">Accessibility</a></li>
                 </ul>
              </li>
              <li className="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Reports</a>
                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                  <li><a href="#" className="dropdown-item">Shipping Companies</a></li>
                  <li><a href="#" className="dropdown-item">User Performance</a></li>
                  <li><a href="#" className="dropdown-item">Activity Reports</a></li>
                 </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">Support</a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">Contact</a>
              </li>
            </ul>
           {/* <li className="nav-item dropdown">
             <a className="nav-link" data-toggle="dropdown" href="#">
               <i className="fas fa-comments"></i>
               <span className="badge badge-danger navbar-badge">3</span>
             </a>
             <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
               <a href="#" className="dropdown-item">
                
                 <div className="media">
                   <img src="../../dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                   <div className="media-body">
                     <h3 className="dropdown-item-title">
                       Brad Diesel
                       <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                     </h3>
                     <p className="text-sm">Call me whenever you can...</p>
                     <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                   </div>
                 </div>
                
               </a>
               <div className="dropdown-divider"></div>
               <a href="#" className="dropdown-item">
               
                 <div className="media">
                   <img src="../../dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                   <div className="media-body">
                     <h3 className="dropdown-item-title">
                       John Pierce
                       <span className="float-right text-sm text-muted"><i className="fas fa-star"></i></span>
                     </h3>
                     <p className="text-sm">I got your message bro</p>
                     <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                   </div>
                 </div>
                
               </a>
               <div className="dropdown-divider"></div>
               <a href="#" className="dropdown-item">
                 
                 <div className="media">
                   <img src="../../dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                   <div className="media-body">
                     <h3 className="dropdown-item-title">
                       Nora Silvester
                       <span className="float-right text-sm text-warning"><i className="fas fa-star"></i></span>
                     </h3>
                     <p className="text-sm">The subject goes here</p>
                     <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                   </div>
                 </div>
                 
               </a>
               <div className="dropdown-divider"></div>
               <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
             </div>
           </li> */}
           
           {/* <li className="nav-item dropdown">
             <a className="nav-link" data-toggle="dropdown" href="#">
               <i className="far fa-bell"></i>
               <span className="badge badge-warning navbar-badge">15</span>
             </a>
             <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
               <span className="dropdown-header">15 Notifications</span>
               <div className="dropdown-divider"></div>
               <a href="#" className="dropdown-item">
                 <i className="fas fa-envelope mr-2"></i> 4 new messages
                 <span className="float-right text-muted text-sm">3 mins</span>
               </a>
               <div className="dropdown-divider"></div>
               <a href="#" className="dropdown-item">
                 <i className="fas fa-users mr-2"></i> 8 friend requests
                 <span className="float-right text-muted text-sm">12 hours</span>
               </a>
               <div className="dropdown-divider"></div>
               <a href="#" className="dropdown-item">
                 <i className="fas fa-file mr-2"></i> 3 new reports
                 <span className="float-right text-muted text-sm">2 days</span>
               </a>
               <div className="dropdown-divider"></div>
               <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
             </div>
           </li> */}
           <li className="nav-item dropdown">
<a className="nav-link" data-target="#userDropDownId" data-toggle="dropdown" href="#">

    <i className="fa fa-user-circle" style={{fontSize: "1.875rem"}}></i>
</a>
<div id="userDropDownId" className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
   <div className="dropdown-divider"></div>
   <a href="#" className="dropdown-item d_lusername">
       <i className="fa fa-user-circle mr-2"></i>
       {loginUser}
   </a>
   <a className="dropdown-item" href="viewProfile"><i className="fa fa-address-card mr-2"></i>Profile</a>
   <div className="dropdown-divider"></div>
   <a href="javascript:void(0)"  onClick={this.logout} className="dropdown-item dropdown-footer">Logout</a>
</div>
</li>
         </ul>
     </div>
    
         
         
        </div>
      </nav>
          )
    }
}
export default Header