import React from 'react';
class Footer extends React.Component{
    render(){
        return( <footer className="main-footer">
          
        <div className="float-right d-none d-sm-inline">
         
        </div>
       
        <strong>Copyright &copy; Bhandarkar Info Tech Solutions <a href="#"></a>.</strong> All rights reserved.
      </footer>);
    }
}
export default Footer;