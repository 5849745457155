import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import React from 'react';
import Header from './header';
import Footer from './footer';

import UserToken from '../function_lib/userToken';
import {GetImgCertUser,CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';

let clsObject;
let loginUser="";
let userObject="";
let initTbResponsive=false;
class Question_Master extends React.Component{
    saveEdu(){
        GetImgCertUser(clsObject,"#frmEdu","importExcelCSV","okEdut");
      }
      okEdut(data,datares,userResp){
        //alert(datares);
        if(userResp!=="0"){
          $("#getReponse").html("Uploaded Successfully");
        }
        if(data!=="0"){
         
        }else{
          $(".save_resp").html(datares);
        }
       
      }
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){

        clsObject=this;
        $("#gb_token").val(userObject[0].app_user_id);
        AjaxCallMethod(this,"okSuccCNOT");

        var map=[];
        map.push({name:"curl_type",value:"getCategory"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"gb_token",value:userObject[0].app_user_id});
        map.push({name:"emp_id",value:userObject[0].id});
        map["res_func"]="callBackNg";
        map["curl_type"]="getCategory";
        CallMapMethod(this,map);

        var qstMap=[];
        qstMap.push({name:"curl_type",value:"getQuestionList"});
        qstMap.push({name:"res_func",value:"callQstMat"});
        qstMap.push({name:"gb_token",value:userObject[0].app_user_id});
        qstMap.push({name:"emp_id",value:userObject[0].id});
        qstMap.push({name:"parent_id",value:"0"});
        qstMap["res_func"]="callQstMat";
        qstMap["curl_type"]="getQuestionList";
        CallMapMethod(this,qstMap);
       

    }
    getQuest(refId){
        var qstMap=[];
        qstMap.push({name:"curl_type",value:"getQuestionList"});
        qstMap.push({name:"res_func",value:"callQstMat"});
        qstMap.push({name:"gb_token",value:userObject[0].app_user_id});
        qstMap.push({name:"emp_id",value:userObject[0].id});
        qstMap.push({name:"parent_id",value:refId});
        qstMap["res_func"]="callQstMat";
        qstMap["curl_type"]="getQuestionList";
        CallMapMethod(this,qstMap);
    }
    //Relative Methods
    callQstMat(data,msg){
        
        if(data!=="0" && data!==""){
            var listItems=JSON.parse(data);
            var str="";
            for(var i=0;i<listItems.length;i++){
            str+="<tr>"+
            "<td>"+(i+1)+"</td>"+
            "<td>"+listItems[i]["category_name"]+"</td>"+
            "<td>"+listItems[i]["id"]+"</td>"+
            "<td>"+listItems[i]["col1"]+"</td>"+
            "<td>"+listItems[i]["col6"]+"</td>"+
            "<td>"+listItems[i]["t_id"]+"</td>"+
            "<td>"+listItems[i]["status"]+"</td>"+"</tr>";
            }
            $("#tbData").html(str);
            $(".total_rec").html(listItems.length);
            if(!initTbResponsive){
                $(".dtgrid").dataTable({
                    "responsive": true,
                    "autoWidth": false,
                });
                initTbResponsive=true;
            }
            
        }
    }
    callBackNg(data,msg){
        // alert(data);
       if(data!=="0" && data!==""){
           var listItems=JSON.parse(data);
           var str="";
           for(var i=0;i<listItems.length;i++){
           str+="<option value=\""+listItems[i]["id"]+"\">"+listItems[i]["category_name"]+"</option>";
           }
           str="<option value=\""+"0"+"\">"+"-Select-"+"</option>"+str;
           $("#cate_mast").html(str);
           $(".total_category_rec").html(listItems.length);
       }
     }

    render(){
        return(<div className="wrapper">
<Header/>
        <div class="content-wrapper">
        <div class="content">
                <div class="card">
                    <div class="card-body">
                        <input type="hidden" name="viewTMapp_manual" id="viewTMapp_manual" value="#" />
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab_1">
                                <section class="content">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Add New</h3>
    
                                                        <div class="card-tools">
                                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <ul class="nav nav-pills flex-column">
                                                            <li class="nav-item">
                                                                <a href="javascript:void(0);" data-toggle="modal" data-target="#divQuestMaster" _hHeight="60%" _hWidth="70%" setl="#" class="btn _hcolorspp btn-block btn-default btn-sm">Add New</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a href="javascript:void(0);" data-toggle="modal" data-target="#famdlt" _hHeight="60%" _hWidth="70%" setl="#" class="btn _hcolorspp btn-block btn-default btn-sm">Excel/CSV Import</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Status within Duration</h3>
    
                                                        <div class="card-tools">
                                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <ul class="nav nav-pills flex-column">
                                                        <li class="nav-item active">
                                                                <a href="#" class="nav-link">
                                                                    <i class="fa fa-building"></i>Categories
                                                                    <span class="badge bg-primary float-right total_category_rec"></span>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item active">
                                                                <a href="#" class="nav-link">
                                                                    <i class="fa fa-building"></i> Q.In Category
                                                                    <span class="badge bg-primary float-right total_rec"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="card card-primary card-outline">
                                                    <div class="card-header">
                                                        <h3 class="card-title">
                                                            <i class="fa fa-edit"></i>
                                                            List Questions
                                                        </h3>
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                                <a href="javascript:void(0);" id="searchEvent" name="searchEvent" class="btn btn-block btn-default btn-sm">Search</a>
                                                            </div>
                                                        </div>
    
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                                <select resp_dest="getQuest" class="form-control select_target select_target_man" id="cate_mast" name="cate_mast">
                                                                    <option value="0">-Select-</option>
                                                                </select>
                                                            </div>
                                                        </div>
    
                                                    </div>
                                                    <div class="card-body">
                                                        <table class="dtgrid table table-bordered table-striped table-ms">
                                                            <thead>
                                                                <tr style={{backgroundColor:"#FC766AFF"}}>
                                                                    <th>S.No.</th>
                                                                    <th>Category</th>
                                                                    <th>Ques Id</th>
                                                                    <th>Question</th>
                                                                    <th>Right Answer</th>
                                                                    <th>Question Level</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="tbData">
                                                            </tbody>
                                                        </table>
                                                        <div class="row" id="tbData1">
    
    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
    
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <Footer/>
<div className="modal fade" id="famdlt">
    <div className="modal-dialog">
    <form id="frmEdu" enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="gb_token" id="gb_token" value="" />
        <div className="modal-content">
        <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Import Question Master</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        
        <div className="card user-data-card" id="step1">
          <div className="card-body">
          <div className="form-group">
                                                  <label className="col-md-3 control-label">Direct Upload</label>
                                                  <div className="col-md-8">
                                                    <input type="file" name="file" id="file" className="form-control" placeholder="Upload" />
                                                  </div>
                                                </div>
          </div>
        </div>
       
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={this.saveEdu}>Upload Now</button>
      </div>
      
        </div>
        </form>
    </div>
</div>
    <div className="modal fade bd-example-modal-xl"  id="divQuestMaster" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Question Master</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <div className="row">
      <div className="col-lg-3 col-md-3 col-sm-6 col-12">
      <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Question Category</label>
            <input type="text" className="form-control" id="parent_id" name="parent_id" />
          </div> 
		         
         </div>
         <div className="col-lg-6 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Question</label>
            <input type="text" className="form-control" id="col1" name="col1" />
          </div>
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Question Level</label>
  <select className="form-control" id="t_id" name="t_id">
      <option value="BEGINNER">BEGINNER</option>
      <option value="INTERMEDIATE">INTERMEDIATE</option>
      <option value="ADVANCED">ADVANCED</option>
    </select>
          </div>
         </div>
         
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Option A</label>
            <input type="text" className="form-control" id="col2" name="col2" />
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Option B</label>
            <input type="text" className="form-control" id="col3" name="col3" />
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Option C</label>
            <input type="text" className="form-control" id="col4" name="col4" />
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Option D</label>
            <input type="text" className="form-control" id="col5" name="col5" />
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-4 font-sm">Correct Answer</label>
            <select className="form-control" id="col6" name="col6">
                <option value="0">-Select-</option>
      <option value="A">A</option>
      <option value="B">B</option>
      <option value="C">C</option>
      <option value="D">D</option>
    </select>
          </div>          
         </div>
      </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
    </div>
    </div>
    
    </div>
    )
    }
}
export default Question_Master;