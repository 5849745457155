import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/Bhandarkarlogo.png';
import defaultPic from '../assets/images/9.png';
//import UserManual from '../assets/Operational_Instructions.pdf';

let loginUser="";
let userObject="";
let companyName="";
class DashboardNew extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          companyName=userObject[0].company_name;
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
        $("#divEmployee").show();
         // alert(userObject[0].type);
          if(userObject[0].type=="3"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getQuizAssignedList"});
            mapCat.push({name:"res_func",value:"callBackQuizList"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackQuizList";
            mapCat["curl_type"]="getQuizAssignedList";
            CallMapMethod(this,mapCat);

            var mapSim=[];
            mapSim.push({name:"curl_type",value:"getSimulatorAssignList"});
            mapSim.push({name:"res_func",value:"callBackSimList"});
            mapSim.push({name:"gb_token",value:userObject[0].app_user_id});
            mapSim.push({name:"emp_id",value:userObject[0].id});
            mapSim["res_func"]="callBackSimList";
            mapSim["curl_type"]="getSimulatorAssignList";
            CallMapMethod(this,mapSim);
          }else if(userObject[0].type=="1"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getCompanyDashboard"});
            mapCat.push({name:"res_func",value:"callBackDash"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackDash";
            mapCat["curl_type"]="getCompanyDashboard";
            CallMapMethod(this,mapCat);
          }else if(userObject[0].type=="2"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getCompanyDashboard"});
            mapCat.push({name:"res_func",value:"callBackAdminDash"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackAdminDash";
            mapCat["curl_type"]="getCompanyDashboard";
            CallMapMethod(this,mapCat);
          }
      }
      callBackAdminDash(data,msg){
           //alert(data);
           var getDash=JSON.parse(data);
           $(".tot_comp").html(getDash.total_company);
           $(".tot_emp").html(getDash.total_employee);
           $(".tot_assigned_quiz").html(getDash.assigned_quiz);
           $(".tot_comp_quiz").html(getDash.completed_quiz);
           $(".tot_pending_quiz").html(getDash.pending_quiz);
          
           $(".tot_assigned_simu").html(getDash.total_simulator);
           $(".tot_pending_simu").html(getDash.pending_simulator);
           $(".tot_comp_simu").html(getDash.completed_simulator);
           $(".sim_vs_total").html(getDash.completed_simulator+"/"+getDash.total_simulator);
           $(".avg_perform").html(getDash.avg_performance+"%");
           $(".quiz_vs_total").html(getDash.completed_quiz+"/"+getDash.assigned_quiz);
           //$(".not_attempt").html(getDash.pending_quiz);
           //alert(getDash.total_employee);
      }
      callBackDash(data,msg){
          //alert(data);
          var getDash=JSON.parse(data);
          $(".tot_emp").html(getDash.total_employee);
          $(".tot_assigned_quiz").html(getDash.assigned_quiz);
          $(".tot_comp_quiz").html(getDash.completed_quiz);
          $(".tot_pending_quiz").html(getDash.pending_quiz);
          $(".sim_vs_total").html(getDash.completed_simulator+"/"+getDash.total_simulator);
          
          $(".avg_perform").html(getDash.avg_performance+"%");
          $(".quiz_vs_total").html(getDash.completed_quiz+"/"+getDash.assigned_quiz);
          //$(".not_attempt").html(getDash.pending_quiz);
          //alert(getDash.total_employee);
      }
      callBackSimList(data,msg){
        //  alert(data);
        if(data!=="0" && data!==""){
            var strList="";
            var quizLink="";
            var quizText="";
            var assignList=JSON.parse(data);
            var totalQuiz=0;
            var totalPending=0;
            var totalCompleted=0;
            for(var i=0;i<assignList.length;i++){
                if(assignList[i]["status"]=="Pending"){
                    totalPending++;
                    quizText="Start Simulator";
                    quizLink="/preDocuments?rf="+assignList[i]["rf_id"]+
                             "&vId="+assignList[i]["video_url"];
                }else{
                    totalCompleted++;
                    quizText="View Performance";
                   quizLink="/certificate?rf="+assignList[i]["rf_id"]
                }
                totalQuiz++;
                strList+="<tr>"+"<td>"+(i+1)+"</td>"+
             "<td>"+assignList[i]["id"]+"</td>"+
             "<td>"+assignList[i]["n_entry_date"]+"</td>"+
             "<td>"+assignList[i]["col4"]+"</td>"+
             "<td>"+assignList[i]["status"]+"</td>"+
             "<td><a href=\""+quizLink+"\">"+quizText+"</a></td>"+
             "</tr>";
            }
            $("#tdDataSim").html(strList);
            $(".tot_assigned_quiz").html(totalQuiz);
            $(".tot_pending_quiz").html(totalPending);
            $(".tot_comp_quiz").html(totalCompleted);
        }
      }
      callBackQuizList(data,msg){
          //alert(data);
          var totalQuiz=0;
          var totalPending=0;
          var totalCompleted=0;
        if(data!=="0" && data!==""){
            var assignList=JSON.parse(data);
            var strList="";
            var quizLink="";
            var quizText="";
            //alert(data);
            for(var i=0;i<assignList.length;i++){
                totalQuiz++;
             quizLink="";
             if(assignList[i]["status"]=="Pending"){
                 totalPending++;
                 quizText="Start Quiz";
                 quizLink="/startQuiz?rf="+assignList[i]["rf_id"]+
                          "&vId="+assignList[i]["video_url"];
             }else{
                 totalCompleted++;
                 quizText="View Performance";
                quizLink="/quizReport?rf="+assignList[i]["rf_id"]
             }
             strList+="<tr>"+"<td>"+(i+1)+"</td>"+
             "<td>"+assignList[i]["id"]+"</td>"+
             "<td>"+assignList[i]["n_entry_date"]+"</td>"+
             "<td>"+assignList[i]["col4"]+"</td>"+
             "<td>"+assignList[i]["category_name"]+"</td>"+
             "<td>"+assignList[i]["status"]+"</td>"+
             "<td><a href=\""+quizLink+"\">"+quizText+"</a></td>"+
             "</tr>";
            }
            $("#tdDataDash").html(strList);
            $(".t_totalQuiz").html(totalQuiz);
            $(".t_pendingQuiz").html(totalPending);
            $(".t_completeQuiz").html(totalCompleted);
        }
      }

    render(){
        
        const adminOption=()=>{
            if(userObject[0].type=="3"){
                return(<div className="row">
                    <div className="col-md-3">
                   
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <div className="widget-user-image">
                               <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                           </div>
                          
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">{loginUser}</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                               <li className="nav-item">
                                   <a href="../performanceReport?rf=0" className="nav-link">
                                       Total Animation Video Assigend<span className="float-right badge bg-primary t_totalQuiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="../performanceReport?rf=2" className="nav-link">
                                       Completed Animation Video <span className="float-right badge bg-success t_completeQuiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="../performanceReport?rf=1" className="nav-link">
                                       Pending Animation Video <span className="float-right badge bg-info t_pendingQuiz"></span>
                                   </a>
                               </li>
                              
                           </ul>
                       </div>
                   </div>
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">Simulator Status</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                          
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=0" className="nav-link">
                                       Assigned Simulator Session<span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=1" className="nav-link">
                                       Pending Simulator Session<span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=2" className="nav-link">
                                       Completed Simulator Session<span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                              
                           </ul>
                       </div>
                   </div>
               </div>
                <div className="col-md-9">
                                    <div className="card">
                                        <div className="card-header border-transparent">
                                            <h3 className="card-title">Pending Animation Video</h3>
                
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-ms">
                                                    <thead>
                                                        <tr style={{backgroundColor:"#FC766AFF"}}>
                                                            <th>S.No.</th>
                                                            <th>Request ID</th>
                                                            <th>Assign Date</th>
                                                            <th>Title</th>
                                                            <th>Category</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="tdDataDash">
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <a href="../performanceReport?rf=0" className="btn btn-sm btn-secondary float-right">View All</a>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header border-transparent">
                                            <h3 className="card-title">Assigned Simulator</h3>
                
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-ms">
                                                    <thead>
                                                        <tr style={{backgroundColor:"#FC766AFF"}}>
                                                            <th>S.No.</th>
                                                            <th>Request ID</th>
                                                            <th>Assign Date</th>
                                                            <th>Title</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="tdDataSim">
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <a href="../simulatorReports?rf=0" className="btn btn-sm btn-secondary float-right">View All</a>
                                        </div>
                                    </div>
                                 </div>
                                
                                
                                </div>)
            }
            else if(userObject[0].type=="1"){
                return(<div className="row">
                <div className="col-md-3">
                   
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <div className="widget-user-image">
                               <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                           </div>
                          
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">{loginUser}</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                          
                               <li className="nav-item">
                                   <a href="/useraccounts" className="nav-link">
                                       Total Employee<span className="float-right badge bg-primary tot_emp"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=0" className="nav-link">
                                       Assigned Animation Video <span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=1" className="nav-link">
                                       Pending Animation Video <span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=2" className="nav-link">
                                       Completed Animation Video <span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                           </ul>
                       </div>
                   </div>
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">Simulator Status</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                          
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=0" className="nav-link">
                                       Assigned Simulator Session<span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=1" className="nav-link">
                                       Pending Simulator Session<span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=2" className="nav-link">
                                       Completed Simulator Session<span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                              
                           </ul>
                       </div>
                   </div>
               </div>
               
               <div className="col-md-9">
                   <div className="card">
                       <div className="card-header border-transparent">
                           <h3 className="card-title">Welcome - {userObject[0].person_name}</h3>

                           <div className="card-tools">
                               <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                   <i className="fa fa-minus"></i>
                               </button>
                               <button type="button" className="btn btn-tool" data-card-widget="remove">
                                   <i className="fa fa-times"></i>
                               </button>
                           </div>
                       </div>
                      
                       <div className="card-body p-0">
                           <div className="row">
                               <div className="col-lg-4 col-6">
                                   
                                   <div className="small-box bg-info">
                                       <div className="inner">
                                       <h3><span className="avg_perform"></span><sup style={{fontSize:"20px"}}></sup></h3>

                                           <p>Average Performance</p>
                                       </div>
                                       <div className="icon">
                                       <i className="fa fa-line-chart"></i>
                                       </div>
                                       <a href="/performanceReport" className="small-box-footer">
                                           Till Date <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                               
                               <div className="col-lg-4 col-6">
                                  
                                   <div className="small-box bg-success">
                                       <div className="inner">
                                       <h3><div className="quiz_vs_total"></div></h3>

                                           <p>Attempted/Total Animation Video</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-plus"></i>
                                       </div>
                                       <a href="/performanceReport" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                             
                               <div className="col-lg-4 col-6">
                                
                                   <div className="small-box bg-warning">
                                       <div className="inner">
                                           <h3><div className="sim_vs_total"></div></h3>
                                           <p>Attempted/Total Simulator</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-user-plus"></i>
                                       </div>
                                       <a href="/simulatorReports" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="card-footer clearfix">

                       </div>
                   </div>
               </div>
               
            </div>);
            }else if(userObject[0].type=="2"){
                return(<div className="row">
                <div className="col-md-3">
                   
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <div className="widget-user-image">
                               <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                           </div>
                          
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">{loginUser}</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                           <li className="nav-item">
                                   <a href="/createcompany" className="nav-link">
                                       Total Companies<span className="float-right badge bg-primary tot_comp"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/useraccounts" className="nav-link">
                                       Total Employee<span className="float-right badge bg-primary tot_emp"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=0" className="nav-link">
                                       Assigned Animation Video <span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=1" className="nav-link">
                                       Pending Animation Video <span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/performanceReport?rf=2" className="nav-link">
                                       Completed Animation Video <span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                           </ul>
                       </div>
                   </div>
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-tbheader">
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">Simulator Status</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                          
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=0" className="nav-link">
                                       Assigned Simulator Session<span className="float-right badge bg-info tot_assigned_simu"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=1" className="nav-link">
                                       Pending Simulator Session<span className="float-right badge bg-danger tot_pending_simu"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/simulatorReports?rf=2" className="nav-link">
                                       Completed Simulator Session<span className="float-right badge bg-success tot_comp_simu"></span>
                                   </a>
                               </li>
                              
                           </ul>
                       </div>
                   </div>
               </div>
               
               <div className="col-md-9">
                   <div className="card">
                       <div className="card-header border-transparent">
                           <h3 className="card-title">Welcome - {userObject[0].person_name}</h3>

                           <div className="card-tools">
                               <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                   <i className="fa fa-minus"></i>
                               </button>
                               <button type="button" className="btn btn-tool" data-card-widget="remove">
                                   <i className="fa fa-times"></i>
                               </button>
                           </div>
                       </div>
                      
                       <div className="card-body p-0">
                           <div className="row">
                               <div className="col-lg-4 col-6">
                                   
                                   <div className="small-box bg-info">
                                       <div className="inner">
                                       <h3><span className="avg_perform"></span><sup style={{fontSize:"20px"}}></sup></h3>

                                           <p>Average Performance</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-line-chart"></i>
                                       </div>
                                       <a href="/performanceReport" className="small-box-footer">
                                           Till Date <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                               
                               <div className="col-lg-4 col-6">
                                  
                                   <div className="small-box bg-success">
                                       <div className="inner">
                                       <h3><div className="quiz_vs_total"></div></h3>

                                           <p>Attempted/Total Animation Video</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-plus"></i>
                                       </div>
                                       <a href="/performanceReport" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                             
                               <div className="col-lg-4 col-6">
                                
                                   <div className="small-box bg-warning">
                                       <div className="inner">
                                           <h3><div className="sim_vs_total"></div></h3>

                                           <p>Attempted/Total Simulator</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-user-plus"></i>
                                       </div>
                                       <a href="/simulatorReports" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="card-footer clearfix">

                       </div>
                   </div>
               </div>
               
            </div>)
            }
            
        }
        return(<div className="wrapper">
            <Header/>
   <div className="content-wrapper">
        <div className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Dashboard</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
            <section className="content">
                <div className="container-fluid">
                
          {adminOption()}
          
                </div>
            </section>
         
         
          
        </div>
        <aside className="control-sidebar control-sidebar-dark">
          
          <div className="p-3">
            <h5>Title</h5>
            <p>Sidebar content</p>
          </div>
        </aside>
        <Footer/>
        
      </div>)
    }
}
export default DashboardNew