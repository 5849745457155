import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {DateTimePicker,RespBkMthdMapp,AjaxCallMethod,CallMapMethod,TableDatePicker} from '../function_lib/common_lib';

import Header from './header';
import Footer from './footer';
import './user_accounts.css';
let loginUser="";
let userObject="";
let clsObject;
let initTbResponsive=false;
class User_Accounts extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
      
          clsObject=this;
          //$("#gb_token").val(userObject[0].app_user_id);
          //$("#gb_token_quiz").val(userObject[0].app_user_id);
          //$("#gb_token_quiz_quiz").val(userObject[0].app_user_id);

          $("#user_token").val(userObject[0].id);
          //$("#col20").val(userObject[0].app_user_id);
          AjaxCallMethod(this,"okSuccCNOT");
          var mapCat=[];
          mapCat.push({name:"curl_type",value:"getCategory"});
          mapCat.push({name:"res_func",value:"callBackCat"});
          mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
          mapCat.push({name:"emp_id",value:userObject[0].id});
          mapCat["res_func"]="callBackCat";
          mapCat["curl_type"]="getCategory";
          CallMapMethod(this,mapCat);
          clsObject.getEmployeeList();
        
      }
      getEmployeeList(){
        var map=[];
        map.push({name:"curl_type",value:"getUserList"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"gb_token",value:userObject[0].app_user_id});
        map.push({name:"emp_id",value:userObject[0].id});
        map["res_func"]="callBackNg";
        map["curl_type"]="getUserList";
        CallMapMethod(this,map);
      }

      callBackCat(data,msg){
        // alert(data);
       if(data!=="0" && data!==""){
           var listItems=JSON.parse(data);
           var str="";
           for(var i=0;i<listItems.length;i++){
           str+="<option value=\""+listItems[i]["id"]+"\">"+listItems[i]["category_name"]+"</option>";
           }
           str="<option value=\""+"0"+"\">"+"-Select-"+"</option>"+str;
           $("#assign_id").html(str);
       }
     }

      //Relative Methods
      callBackNg(data,msg){
       // alert(data);
      if(data!=="0" && data!==""){
          var listItems=JSON.parse(data);
          var str="";
          for(var i=0;i<listItems.length;i++){
          str+="<tr>"+
          "<td>"+(i+1)+"</td>"+
          "<td>"+listItems[i]["entry_date"]+"</td>"+
          "<td>"+listItems[i]["comp_name"]+"</td>"+
          "<td>"+listItems[i]["col1"]+"</td>"+
          "<td>"+listItems[i]["col2"]+"</td>"+
          "<td>"+listItems[i]["col3"]+"</td>"+
          "<td>"+listItems[i]["col5"]+"</td>"+
          "<td>"+listItems[i]["col6"]+"</td>"+
          "<td>"+listItems[i]["status"]+"</td>"+
          "<td>"+"<a href=\"javascript:void(0)\" class=\"ref_quiz\" param1=\""+listItems[i]["id"]+"\" data-toggle=\"modal\" data-target=\"#divSimu\">Allot Simulator</a><br>Pending-"+
          listItems[i]["pending_simulator"]+"<br>"+"Total-"+listItems[i]["alloted_simulator"]+"</td>"+
          "<td>"+"<a href=\"javascript:void(0)\" class=\"ref_quiz\" param1=\""+listItems[i]["id"]+"\" data-toggle=\"modal\" data-target=\"#divTest\">Allot Animation Video</a><br>Pending-"+
          listItems[i]["pending_video"]+"<br>Total-"+listItems[i]["alloted_video"]+"</td>"+
          "</tr>";
          }
          $("#tbData").html(str);
          $(".total_rec").html(listItems.length);
          RespBkMthdMapp(clsObject,"setEmpToAssi","ref_quiz");
          if(!initTbResponsive){
            $(".dtgrid").dataTable({
                "responsive": true,
                "autoWidth": false,
            });
            initTbResponsive=true;
        }
      }
    }
    setEmpToAssi(refId){
        $("#user_token").val(refId);
        $("#user_token_quiz").val(refId);
    }
      okSuccCNOT(data,datares){
         // alert(datares);
        if(data!="0" && data!==""){
            $("#frmEmpCreate").find("input[type=text]").val("");
          $("#getReponse").html("Employee Created Successfully..");
          clsObject.getEmployeeList();
        }else{
          $("#getReponse").html(datares);
        }
    }
    okSuccCAssi(data,datares){
        //alert(data);
        if(data!="0" && data!==""){
          $("#frmEmpCreate_simu").find("input[type=text]").val("");
          $("#getReponseQuiz").html("Alloted Successfully");
        }else{
            $("#getReponseQuiz").html("Unable to assign");
        }
    }
    okSuccCAssiQuiz(data,datares){
      //alert(data);
      if(data!="0" && data!==""){
        $("#frmEmpCreate_Quiz").find("input[type=text]").val("");
        $("#getReponseQuiz_Quiz").html("Alloted Successfully");
      }else{
          $("#getReponseQuiz_Quiz").html("Unable to assign");
      }
  }
    render(){
        return(<div className="wrapper">
<Header/>
        <div class="content-wrapper">
        <div class="content">
                <div class="card">
                    <div class="card-body">
                        <input type="hidden" name="viewTMapp_manual" id="viewTMapp_manual" value="#" />
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab_1">
                                <section class="content">
                                    <div class="container-fluid">
                                        <div class="row">
                                          
                                            <div class="col-md-12">
                                                <div class="card card-primary card-outline">
                                                    <div class="card-header">
                                                        <h3 class="card-title">
                                                            <i class="fa fa-edit"></i>
                                                            Employee List-Total Records -<span class="badge bg-primary float-right total_rec">0</span>
                                                        </h3>
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#divEmployee" _hHeight="60%" _hWidth="70%" setl="#" class="btn _hcolorspp btn-block btn-default btn-sm">Add New</a>
                                                            </div>
                                                        </div>
    
                                                    </div>
                                                    <div class="card-body">
                                                        <table class="dtgrid table table-bordered table-striped table-ms">
                                                            <thead>
                                                                <tr style={{backgroundColor:"#FC766AFF"}}>
                                                                    <th>S.No.</th>
                                                                    <th>Creation Date</th>
                                                                    <th>Company Name</th>
                                                                    <th>Emp Code</th>
                                                                    <th>Password</th>
                                                                    <th>Employee Name</th>
                                                                    <th>Contact No</th>
                                                                    <th>Employee Designation</th>
                                                                    <th>Status</th>
                                                                    <th>Simulator</th>
                                                                    <th>Animation Video</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="tbData">
                                                            </tbody>
                                                        </table>
                                                        <div class="row" id="tbData1">
    
    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
    
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <Footer/>

    <div className="modal fade bd-example-modal-xl"  id="divEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Employee Master</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmEmpCreate">
      <input type="hidden" name="curl_type" id="curl_type" value="createCompany" />  
      <input type="hidden" name="type" id="type" value="3" />
                <input type="hidden" name="m_type" id="m_type" value="createCompany" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                <input type="hidden" name="gb_token" id="gb_token" value={userObject[0].app_user_id}/>
                <input type="hidden" name="col20" id="col20" value={userObject[0].app_user_id}/>
      <div className="modal-body">
      <div className="row">
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Employee Name *</label>
            <input type="text" className="form-control required" id="col3" name="col3" />
          </div>
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Employee Code/CDC No. *</label>
            <input type="text" className="form-control required" id="col1" name="col1" />
          </div>
         </div>
        
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Contact No *</label>
            <input type="text" className="form-control required" id="col5" name="col5" />
          </div>
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Email *</label>
            <input type="text" className="form-control" id="col11" name="col11" />
          </div>
         </div>
         
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Designation</label>
            <input type="text" className="form-control" id="col6" name="col6" />
          </div>          
         </div>
      </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    <div className="modal fade bd-example-modal-xl"  id="divSimu" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl reveal-modal" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Assign Simulator</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmEmpCreate_simu" name="frmEmpCreate_simu">
      <input type="hidden" name="curl_type" id="curl_type" value="assignSimul" />  
      <input type="hidden" name="type" id="type" value="222" />
                <input type="hidden" name="m_type" id="m_type" value="assignSimul" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCAssi" />
                <input type="hidden" name="gb_token_quiz" id="gb_token_quiz" value={userObject[0].app_user_id}/>
                <input type="hidden" name="user_token" id="user_token" value="0"/>
                <input type="hidden" name="status" id="status" value="Pending"/>
                
      <div className="modal-body">
      <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Title</label>
            <input type="text" className="form-control required" id="col4" name="col4" />
          </div>          
         </div>
         
         <div className="col-lg-4 col-md-4 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Quiz Date</label>
            <br></br>
            <DateTimePicker refName="entry_date"/>
          </div>
         </div>
         <div className="col-lg-4 col-md-4 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Level</label>
            <select disabled class="form-control select_target_man" id="t_id" name="t_id">
                                                                    <option value="0">-Select-</option>
                                                                    <option value="BEGINNER">BEGINNER</option>
                                                                    <option value="INTERMEDIATE">INTERMEDIATE</option>
                                                                    <option value="ADVANCED">ADVANCED</option>
                                                                </select>
          </div>
         </div>
         
        
         <div className="col-lg-4 col-md-4 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Port</label>
            <select className="form-control required" id="col6" name="col6">
              <option disabled value="0"> -Select- </option>
              <option disabled value="Gulf of Kutch">Gulf of Kutch</option>
              <option disabled value="English Channel">English Channel</option>
              <option disabled value="Mumbai JNPT">Mumbai JNPT</option>
              <option value="Rivera">Rivera</option>
              <option disabled value="Singapore">Singapore</option>
            </select>
            
          </div>          
         </div>
      </div>
      </div>
      <div className="modal-footer">
      <div id="getReponseQuiz" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    <div className="modal fade bd-example-modal-xl"  id="divTest" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Assign New Test</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmEmpCreate_Quiz" name="frmEmpCreate_Quiz">
      <input type="hidden" name="curl_type" id="curl_type" value="assignQuizEmp" />  
      <input type="hidden" name="type" id="type" value="111" />
                <input type="hidden" name="m_type" id="m_type" value="assignQuizEmp" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCAssiQuiz" />
                <input type="hidden" name="gb_token_quiz_quiz" id="gb_token_quiz_quiz" value={userObject[0].app_user_id}/>
                <input type="hidden" name="user_token_quiz" id="user_token_quiz" value="0"/>
                <input type="hidden" name="status" id="status" value="Pending"/>
                
      <div className="modal-body">
      <div className="row">
      <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Title</label>
            <input type="text" className="form-control required" id="col4" name="col4" />
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Select Quiz</label>
            <div class="input-group input-group-sm" style={{width: "100%"}}>
                                                                <select class="form-control required select_target_man" id="assign_id" name="assign_id">
                                                                    <option value="0">-Select-</option>
                                                                </select>
                                                            </div>
          </div>
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Quiz Date</label>
            <br></br>
            <TableDatePicker refName="entry_date"/>
          </div>
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Level</label>
            <select class="form-control required select_target_man" id="t_id" name="t_id">
                                                                    <option value="0">-Select-</option>
                                                                    <option value="BEGINNER">BEGINNER</option>
                                                                    <option value="INTERMIDIATE">INTERMIDIATE</option>
                                                                    <option value="HIGHER">HIGHER</option>
                                                                </select>
          </div>
         </div>
         
        
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Description</label>
            <input type="text" className="form-control" id="col6" name="col6" />
          </div>          
         </div>
      </div>
      </div>
      <div className="modal-footer">
      <div id="getReponseQuiz_Quiz" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    </div>
    )
    }
}
export default User_Accounts;