import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {AjaxCallMethod,RespBkMthdMapp} from '../function_lib/common_lib';

import { Player } from 'video-react';
import LogoImg from '../assets/images/Bhandarkarlogo.png';
import titleVid from '../assets/videos/loginbackground.mp4';



let loginUser="";
let userObject="";
class Index extends React.Component{
  constructor(props){
    super(props);
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      userObject= UserToken.getUserObject();
      loginUser=userObject[0].person_name;
      window.location.href="./Dashboard";
    }
  }
  okSuccCNOT(data,datares){
    //alert(data);
    if(data!="0" && data!=""){
      $("#getReponse").html("Login Successfully..");
     localStorage.setItem('userData',data);
     window.location.href="./dashboard";
    }else{
      $("#getReponse").html("Invalid UserName or Password !");
    }
}
componentDidMount(){
  AjaxCallMethod(this,"okSuccCNOT");
  RespBkMthdMapp(this,"","keyEvent");
}
    render(){
        return(<div>
          
             <video autoPlay muted loop id="myVideo">
  <source src={titleVid} type="video/mp4"/>
</video>
            <div className="content">
                
                
                <div className="container mt-5">
                
                 <div className="row justify-content-md-center">
                    <div className="col-6 col-md-6 col-xl-6 col-sm-11 col-md-auto col-12">
                        
                        <div className="card">
                        <div className="card-header">
                            <img src={LogoImg}  className=" m-auto img-fluid  d-flex" />
                            <h6 className="ss-font-style-2 text-center text-black-50 mt-3 ">Hello there! Sign in and start managing your Shipping Account</h6>
                            </div>
                            <div className="card-body">
                            
                            <form>
                            <input type="hidden" name="curl_type" id="curl_type" value="getAccessToken" />  
                <input type="hidden" name="m_type" id="m_type" value="getAccessToken" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
              <div className="form-row">
                <div className="col-md-12 mb-12">
                  <label for="col1">UserName</label>
                  <input type="text" className="form-control" name="col1" id="col1" placeholder="UserName/Contact No" required />
                   <div className="valid-feedback">
                    
                  </div> 
                </div>
                
              </div>
                                
                                
                                <div className="form-row">
                <div className="col-md-12 mb-12">
                  <label for="col2">Password</label>
                  <input type="password" className="form-control keyEvent" rf="btnLogin" name="col2" id="col2" placeholder="Password" required />
                   <div className="valid-feedback">
                    
                  </div> 
                </div>
                
              </div>
              
              {/* <div className="form-group">
                <div className="form-check">
                  <input className="form-check-input is-invalid" type="checkbox" value="" id="invalidCheck3" aria-describedby="invalidCheck3Feedback" required />
                  <label className="form-check-label" for="invalidCheck3">
                    Agree to terms and conditions
                  </label>
                  <div  id="invalidCheck3Feedback" className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
              </div> */}
              <div id="getReponse" style={{color:"red"}}></div><br></br>
              <button id="btnLogin" className="btn btn-primary _btnSetColor m-auto d-block d-flex" type="button">Login</button>
            </form>
                            
                            </div>
                            
                            
                            
                        </div>
                    
                    
                    </div>
                    
                    </div>
                
                
                </div>
             
            </div></div>);
    }
}
export default Index