import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';
import Dashboard from './user_pages/dashboard'
import DashboardNew from './user_pages/dashboard_new';
import CreateCompany from './user_pages/create_company';
import QuizCategory from './user_pages/quiz_category';
import QuestMaster from './user_pages/question_master';
import UserAccounts from './user_pages/user_accounts';
import TutorialPlay from './user_pages/play_video';
import StartQuiz from './user_pages/start_quiz';
import QuizReportTmp from './user_pages/quiz_report_tmp';
import QuizReport from './user_pages/quiz_report';
import PreDocuments from './user_pages/pre_document_simulator';
import StartSimu from './user_pages/start_simul';
import SimulatorReports from './user_pages/rpt_simulator';
import CompanyReports from './user_pages/company_reports';
//Reporting Modules
import RptPerfoReports from './user_pages/rpt_perfo_report';
import RptAnalyticReports from './user_pages/emp_analytics_avg';
import SmLog from './pages/sm_log';
import Certificate from './user_pages/promo_certificate';

//Remove after done
import TestWhat from './function_lib/testwhats';

class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
  render(){
   
    return <Router>
      <Route exact path="/" component={Pages} />
      <Route exact path="/Dashboard" component={DashboardNew}/>
      <Route exact path="/createcompany" component={CreateCompany} />
      <Route exact path="/company_reports" component={CompanyReports} />
      <Route exact path="/animation_videos" component={QuizCategory} />
      <Route exact path="/questmaster" component={QuestMaster} />
      <Route exact path="/useraccounts" component={UserAccounts} />
      <Route exact path="/tutorialPlay" component={TutorialPlay} />
      <Route exact path="/startQuiz" component={StartQuiz} />
      <Route exact path="/StartSimu" component={StartSimu}/>
      <Route exact path="/quizReportTmp" component={QuizReportTmp} />
      <Route exact path="/quizReport" component={QuizReport} />
      <Route exact path="/performanceReport" component={RptPerfoReports}/>
      <Route exact path="/empAnalyticsReport" component={RptAnalyticReports} />
      <Route exact path="/smlog" component={SmLog} />
      <Route exact path="/certificate" component={Certificate} />
      <Route exact path="/simulatorReports" component={SimulatorReports}/>
      <Route exact path="/preDocuments" component={PreDocuments} />
      <Route exact path="/TestWhat" component={TestWhat} />
    </Router>
  }
  
}
export default App;