import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import UserToken from '../function_lib/userToken';
import {getAttemptQuestions,GetGraphData,CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';

import Header from './header';
import Footer from './footer';
import { FormControl } from 'react-bootstrap';
import './quiz_report.css';
import { parse } from '@fortawesome/fontawesome-svg-core';

let loginUser="";
let userObject="";
let graphData;
let grpahTitle;
let rf=0;
let queries;
class Quiz_Report extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
            if(queries.rf!=null){
              rf=queries.rf;
            }
            
          }
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
     
     
      componentDidMount(){
        //alert(JSON.stringify(this.getGraphData()));
        var map=[];
        map.push({name:"curl_type",value:"getQuizReport"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"gb_token",value:userObject[0].app_user_id});
        map.push({name:"emp_id",value:userObject[0].id});
        map.push({name:"result_id",value:rf});
        map["res_func"]="callBackNg";
        map["curl_type"]="getQuizReport";
        CallMapMethod(this,map);
       
      
      }
      callBackNg(data,msg){
        //alert(msg);
        var resData= getAttemptQuestions(data);
        $("#quest").html(resData);
        
        var perChart=JSON.parse(msg);
        var totalQut=parseInt(perChart[0]["col3"]);
        var yCorrect=parseInt(perChart[0]["col1"]);
        var yNoAttempt=0;
        var yWrong=totalQut-yCorrect;
        var yWrong=yWrong-yNoAttempt;
        
        grpahTitle=perChart[0]["col2"];

        graphData=[
          { y: yCorrect, label: "Correct" },
          { y: yWrong, label: "Wrong" },
          { y: yNoAttempt, label: "Not Attemped" }
          
        ];
       this.forceUpdateHandler();
    }
    render(){
       //alert("i am in render");
        return(<div className="wrapper">
<Header/>
<div class="content-wrapper">
<div class="row">
<div className="col-6">
    <div class="card">
                    <div class="card-body">
                    <GetGraphData setData={graphData} titleg={grpahTitle} />
                        </div>
                        </div>
   
    </div>
    <div id="quest" className="col-6"></div>
   
</div>
</div>
<Footer/>
        </div>)
    }
}
export default Quiz_Report